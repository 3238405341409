@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "IBM Plex Sans JP", system-ui, sans-serif;
    letter-spacing: 0.025em;
  }
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}